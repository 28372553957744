import onload from "script-onload";

function drainAnalytics(err) {
  if (err) {
    console.error("Unable to load floorlytics integrations script", err);
    return;
  }

  const analyticsq = window.analytics || [];
  const analyticsInstance = window.__floorlytics__;

  if (typeof analyticsInstance == null) {
    console.error("Global __floorlytics__ could not be found");
    return;
  }

  // Make any queued calls up before the full analytics.js library
  // loaded
  while (analyticsq && analyticsq.length > 0) {
    const args = analyticsq.shift();
    const method = args.shift();

    if (typeof analyticsInstance[method] === "function") {
      analyticsInstance[method].apply(analytics, args);
    }
  }

  window.analytics = window.__floorlytics__;
}

function setupAnalytics() {
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) {
    return analytics;
  }

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error("Floorlytics snippet included twice.");
    }
    return analytics;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    "trackSubmit",
    "trackClick",
    "trackLink",
    "trackForm",
    "pageview",
    "identify",
    "reset",
    "group",
    "track",
    "ready",
    "alias",
    "debug",
    "page",
    "once",
    "off",
    "on",
    "setAnonymousId",
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (_key, options) {
    // Create an async script element based on your key.
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = __ANALYTICS_SCRIPT_SRC__;

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName("script")[0];
    first.parentNode.insertBefore(script, first);
    window.analytics._loadOptions = options;
    onload(script, drainAnalytics);
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = __ANALYTICS_SNIPPET_VERSION__;

  // Set the global window analytics
  window.analytics = analytics;

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(__ANALYTICS_WRITE_KEY__, __ANALYTICS_OPTIONS__);

  return analytics;
}

setupAnalytics();
